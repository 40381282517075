import { styled, css } from "styled-components";
import theme from "styled-theming";

import Button from "components/Button";
import Icon from "components/Icon";
import { styleHeadingS } from "style/components/Typography";
import { MQ } from "style/mediaQueries";

export const borderColor = theme("contentTheme", {
    white: "--color-white-o15",
    black: "--color-black-o15",
    cyan: "--color-cyan-o20",
    blue: "--color-blue-o15",
    image: "--color-white-o15",
});

const linkHoverBorderColor = theme("theme", {
    white: "--color-black",
    lightgray: "--color-black",
    blue: "--color-cyan-l60",
    cyan: "--color-blue",
    image: "--color-white",
});

const linkBorderColor = theme("theme", {
    white: "--color-black-o30",
    lightgray: "--color-black-o30",
    blue: "--color-cyan-l60-o30",
    cyan: "--color-blue-o30",
    image: "--color-white-o30",
});

export const Container = styled.div`
    border-top: 1px solid var(${borderColor});
    padding: 1.3125rem 0;

    &:last-of-type {
        border-bottom: 1px solid var(${borderColor});
    }

    ${MQ.FROM_M} {
        padding: 1.75rem 0;
    }
`;

export const Description = styled.section`
    padding-left: 2.1875rem;
    padding-top: 1.3125rem;
    transition: padding-top 0.2s ease-in-out;
    ${theme("open", {
        false: css`
            display: none;
        `,
    })};

    & > :last-child {
        margin-bottom: 0.4375rem;

        ${MQ.FROM_M} {
            margin-bottom: 0.875rem;
        }
    }
`;

export const ArrowIcon = styled(Icon)`
    inset: 0;
    position: absolute;
    transform: ${theme("open", {
        true: "rotate(90deg)",
        false: "none",
    })};
    transition: transform 0.2s ease-in-out;
`;

export const HeadingWrapper = styled.h3`
    margin: 0;
`;

export const Heading = styled.button`
    all: unset;
    ${styleHeadingS};
    cursor: pointer;
    display: block;
    margin: 0;
    padding-left: 2.1875rem;
    position: relative;
    user-select: none;

    span {
        border-color: var(${linkBorderColor});
        transition:
            border-color 0.2s ease-in-out,
            color 0.2s ease-in-out;
    }

    &:hover {
        text-decoration: none;

        span {
            border-bottom: 1px solid var(${linkHoverBorderColor});
        }
    }

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }
`;

export const FaqButton = styled(Button)`
    && {
        margin-top: 1.3125rem;
    }
`;
