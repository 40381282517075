import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import React, { ReactElement, useContext, useState } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import TextBlock from "components/TextBlock";
import { useIsInEditMode } from "hooks";
import {
    addEditAttributes,
    addEditAttributesBlockLevel,
} from "utils/episerver";

import {
    Container,
    Heading,
    HeadingWrapper,
    Description,
    ArrowIcon,
    FaqButton,
} from "./FrequentlyAskedQuestion.styled";
import FrequentlyAskedQuestionProps from "./FrequentlyAskedQuestionProps";

const FrequentlyAskedQuestion = ({
    question,
    answer,
    open,
    link,
    id,
    contentId,
}: FrequentlyAskedQuestionProps): ReactElement => {
    const [isActive, setIsActive] = useState(open);

    const sendDataToGTM = useGTMDispatch();

    const isInEditMode = useIsInEditMode();

    const handleKeyPress = (
        event: React.KeyboardEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        if (event?.key === "Enter") setIsActive(!isActive); // eslint-disable-line
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (!isActive) {
            sendDataToGTM({
                event: "faq_click",
                click_text: question,
            });
        }
        setIsActive(!isActive); // eslint-disable-line
    };

    const themeContext = useContext(ThemeContext);
    const actionTheme = (themeContext && themeContext.actionTheme) || "black";
    return (
        <ThemeProvider
            theme={{
                open: isActive || isInEditMode,
            }}
        >
            <Container {...addEditAttributesBlockLevel(contentId)} id={id}>
                <HeadingWrapper {...addEditAttributes("Question")}>
                    <Heading
                        onClick={(event) => handleClick(event)}
                        onKeyPress={(event) => handleKeyPress(event)}
                        aria-controls={`faq-panel-${id}`}
                        id={`faq-header-${id}`}
                        tabIndex={0}
                    >
                        <ArrowIcon icon="arrowRight28" aria-hidden="true" />
                        {question && (
                            <span
                                dangerouslySetInnerHTML={{ __html: question }}
                            />
                        )}
                    </Heading>
                </HeadingWrapper>
                <Description
                    aria-expanded={isActive ? "true" : "false"}
                    aria-labelledby={`faq-header-${id}`}
                    id={`faq-panel-${id}`}
                    {...addEditAttributes("Answer")}
                >
                    {answer !== undefined && (
                        <TextBlock content={answer} areaName="mainBody" />
                    )}

                    {link?.linkText && (
                        <FaqButton
                            variant="outline"
                            size="medium"
                            isLink={true}
                            actionTheme={actionTheme}
                            {...link}
                            {...addEditAttributes("Link")}
                        />
                    )}
                </Description>
            </Container>
        </ThemeProvider>
    );
};

export default React.memo(FrequentlyAskedQuestion);
